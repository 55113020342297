body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  background-color: #eff0f6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #e4e4e4;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #aaaaaa;
  }
  *::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e;
  }
  *::-webkit-scrollbar-thumb:active {
    background: #466aeb;
  }
}

a,
a:visited {
  text-decoration: none;
}
